.container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0px 20px 30px 20px;
    font-size: 20px;
    color: #FFFFFF;
    font-style: normal;
    line-height: 24px;
    min-height: 74.4vh;
    margin-top: 50px;
}

.title {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
}

h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 49px;
    margin-bottom: 8px;
}

.toggle {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
}

.toggleGroup {
    margin: 0px 0 0 20px;
    background: linear-gradient(117.9deg, rgba(255, 255, 255, 0.4) 10.75%, rgba(255, 255, 255, 0.1) 79.25%);
    box-shadow: 0px 4px 24px -1px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(40px);
    border-radius: 20px !important;
}

.toggleGroupXs {
    margin: 0px 0 0 20px;
    background: linear-gradient(117.9deg, rgba(255, 255, 255, 0.4) 10.75%, rgba(255, 255, 255, 0.1) 79.25%);
    box-shadow: 0px 4px 24px -1px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(40px);
    border-radius: 20px !important;
}

.toggleButton {
    font-family: 'Montserrat' !important;
    border-radius: 25px !important;
    padding: 0px 32px !important;
    font-size: 12px !important;
    text-transform: none !important;
}

.toggleButtonXs {
    font-family: 'Montserrat' !important;
    border-radius: 25px !important;
    padding: 0px 20px !important;
    font-size: 12px !important;
    text-transform: none !important;
}
