.send {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 5px;
}

.timeLeft {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    width: 50px;
    height: 50px;
}

.balance {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    margin-left: 20px;
}

.deposit {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
}

.lp {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
}

.marginLeft20{
    margin: 0 0 0 20px!important;
    height: 30px!important;
}

.marginLeft30{
    margin: 0 0 0 30px!important;
    height: 30px!important;
}
