.itemContainer {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.cardContent {
    padding:0 30px;
    color: #FFFFFF;
}

.name {
    padding: 10px;
    font-stretch: expanded;
    font-weight: bolder;
}

.box {
    justify-content: space-between;
    width: 100%;
    padding: 10px 0;
    margin:0;
}

.contentBox {
    align-items: center;
}

.myStake {
    font-size: 15px;
    padding: 5px;
    line-height: 1.4375em;
    background: transparent;
    color: #e5e8ec;
    border-radius: 10px;
    transition: width 300ms ease;
    overflow-wrap: break-word;
    display: flex;
}

.apy {
    font-size: 15px;
    padding: 5px;
    line-height: 1.4375em;
    background: transparent;
    color: #e5e8ec;
    border-radius: 10px;
    transition: width 300ms ease;
    overflow-wrap: break-word;
    display: flex;
}

.textapy {
    margin-right: 90px;
}

.content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 80px;
}

.history {
    font-size: 10px;
    color: #FFFFFF;
}

.historyIcon {
    fill: #E4F600 !important;
}

.historyButton {
    padding: 0 !important;
}

.historyContent {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2px;
    margin-top: -30px;
}

@media screen and (max-width: 500px) {

    .columnInfo {
        display: flex;
        flex-direction: row;
    }

    .myStake {
        margin: auto;
    }

}
