.cardContent {
    flex-direction: row;
    width: 95%;
    border-bottom: 1px solid #E4F600;
    padding-bottom: 15px;
    justify-content: space-between;
}

.cardAmount {
    color: #E4F600;
    font-size: 16px;
}

/*@media (max-width: 450px) {
    .cardContent {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        width: 200px;
        height: 80px;
    }
}*/
