.containerColor {
    color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    align-content: center;
    margin-top: 100px;
}

.loginContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.formInput {
    width: 275px;
    min-width:250px;
    line-height: 1.4375em !important;
    padding: 7px 10px !important;
    background: rgb(50, 50, 50) !important;
    font-size: 13px !important;
    color: #e5e8ec !important;
    border-radius: 5px !important;
    transition: width 300ms ease;
    border: transparent;
    margin: 20px 0;
}

.formInput:focus {
    outline: none !important;
}
