@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
    background-color: #171819;
    font-family: 'Montserrat' !important;
    margin: 0;
    min-height: 100% !important;
}

.css-ueukts-MuiButtonBase-root-MuiToggleButton-root.Mui-selected {
    color: #171819;
    background-color: #E4F600 !important;
}

.css-1ex1afd-MuiTableCell-root, .css-1ygcj2i-MuiTableCell-root {
    padding: 15px 10px !important;
}

.MuiPaper-root {
    color: #fff !important; 
    background-color: #171819 !important;
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
    font-family: 'Montserrat' !important;
}


.css-10hburv-MuiTypography-root {
    font-family: 'Montserrat' !important;
}

.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked {
    color: #E4F600!important;
}
