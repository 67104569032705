.itemContainer {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.listItem {
    padding-top: 20px;
}

.cardContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #FFFFFF;
}

.name {
    padding: 10px;
    font-weight: 600;
    text-align: center;
    font-stretch: expanded;
    margin-bottom: 20px;
}

.box {
    justify-content: space-between;
    width: 100%;
    padding: 10px 0;
    margin:0;
}

.myStake {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    padding: 5px;
    line-height: 1.4375em;
    background: transparent;
    color: #e5e8ec;
    border: 1px solid #e5e8ec;
    border-radius: 10px;
    transition: width 300ms ease;
    overflow-wrap: break-word;
}

.apy {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    padding: 5px;
    line-height: 1.4375em;
    background: transparent;
    color: #e5e8ec;
    border: 1px solid #e5e8ec;
    border-radius: 10px;
    transition: width 300ms ease;
    overflow-wrap: break-word;
}

.content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

.history {
    font-size: 10px;
    color: #FFFFFF;
}

.historyIcon {
    fill: #E4F600 !important;
}

.historyButton {
    padding: 0 !important;
}

.historyContent {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2px;
}