@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
.Toolbar_bar__TT58B {
    background-color: #171819 !important;
    font-family: 'Montserrat' !important;
    min-height: 90px;
    padding-right: 0!important;
}

.Toolbar_routeBtn__3AEnC {
    font-family: 'Montserrat' !important;
    text-transform: none !important;
    margin-left: 20px !important;
    color: #FFFFFF !important;
}

.Toolbar_balance__2Mqpk {
    display: flex;
    align-items: center;
    font-family: 'Montserrat' !important;
    font-style: normal;
    font-weight: 600;
    font-size: 15px !important;
    line-height: 18px;
    flex-wrap: wrap;
    color: #FFFFFF;
}

.Toolbar_trendIcon__4WEEa {
    fill: #E4F600 !important;
    width: 10px!important;
}

.Toolbar_accountText__3ey9m {
    max-width: 150px;
    text-overflow: ellipsis;
    font-size: 10px;
    margin:0;
    padding:5px!important;
    overflow: hidden;
    white-space: nowrap;
}

.Toolbar_marginPadding0__1W-NI{
    padding:5px!important;
    margin:0!important;
}

.Toolbar_selected__3-hE- {
    font-family: 'Montserrat' !important;
    margin: 20px 0 !important;
    background: linear-gradient(270deg, #E4F600 5.48%, rgba(173, 235, 48, 0.5) 88.01%) !important;
    border-radius: 0 0 0 20px !important;
    color: white !important;
}

.Toolbar_selectedMenu__2KA3D {
    font-family: 'Montserrat' !important;
    background: linear-gradient(270deg, #E4F600 5.48%, rgba(173, 235, 48, 0.5) 88.01%) !important;
    border-radius: 0 0 0 20px !important;
    color: white !important;
}

.Toolbar_txt__3HxWW {
    font-family: 'Montserrat' !important;
}

.stakeTextInput_send__3KGVZ {
    padding: 0 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 5px;
}

.stakeTextInput_timeLeft__O1hA6 {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    width: 100px;
}

.stakeTextInput_balance__bNhsi {
    display: flex;
    flex-direction: column;
    font-size: 12px;
}

.stakeTextInput_deposit__1qbbY {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    grid-gap: 10px;
    gap: 10px;
    align-items: center;
}

.stakeTextInput_lp__C1Dkq {
    display: flex;
    flex-direction: row;
    grid-gap: 10px;
    gap: 10px;
    align-items: center;
}

.stakeTextInput_marginLeft20__3ReMb{
    margin: 0 0 0 20px!important;
    height: 30px!important;
}

.stakeTextInput_marginLeft30__TswNX{
    margin: 0 0 0 30px!important;
    height: 30px!important;
}


.stakeTextInput_enableBtn__LzY2R {
    background: linear-gradient(270deg, rgba(173, 235, 48, 0.5) 5.48%,#E4F600  88.01%) !important;
    color: white!important;
    padding: 20px 0 !important;
}


.stakeItem_itemContainer__2dl4g {
    display: flex;
    flex-direction: row;
    grid-gap: 20px;
    gap: 20px;
}

.stakeItem_listItem__1IplK {
    padding-top: 20px;
}

.stakeItem_cardContent__3r9Vp {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #FFFFFF;
}

.stakeItem_name__eTokE {
    padding: 10px;
    font-weight: 600;
    text-align: center;
    font-stretch: expanded;
    margin-bottom: 20px;
}

.stakeItem_box__3AlRS {
    justify-content: space-between;
    width: 100%;
    padding: 10px 0;
    margin:0;
}

.stakeItem_myStake__11Ij8 {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    padding: 5px;
    line-height: 1.4375em;
    background: transparent;
    color: #e5e8ec;
    border: 1px solid #e5e8ec;
    border-radius: 10px;
    transition: width 300ms ease;
    overflow-wrap: break-word;
}

.stakeItem_apy__2pqnP {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    padding: 5px;
    line-height: 1.4375em;
    background: transparent;
    color: #e5e8ec;
    border: 1px solid #e5e8ec;
    border-radius: 10px;
    transition: width 300ms ease;
    overflow-wrap: break-word;
}

.stakeItem_content__1fLIY {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
}

.stakeItem_history__XmLtn {
    font-size: 10px;
    color: #FFFFFF;
}

.stakeItem_historyIcon__2GIWk {
    fill: #E4F600 !important;
}

.stakeItem_historyButton__UXVaN {
    padding: 0 !important;
}

.stakeItem_historyContent__1YYs8 {
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: 2px;
    gap: 2px;
}
.token_values__3oqLn {
    padding: 20px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #e5e8ec;
  }

.token_header__3LtAO {
    background: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.token_logo__3gvWm {
    width: 100px;
    padding: 5px;
}

.token_title__21MPN {
    font-size: 1.5em;
}

td {
    color: #e5e8ec !important;
}
.withdrawTextInput_send__ngHYE {
    padding: 0 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 5px;
}

.withdrawTextInput_timeLeft__3nfer {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    width: 100px;
}

.withdrawTextInput_disabledBtn__2VVrs {
    background-color: grey !important;

}

.withdrawTextInput_enableBtn__hrCk0 {
    background: linear-gradient(270deg, rgba(173, 235, 48, 0.5) 5.48%,#E4F600  88.01%) !important;
    color: white!important;
    padding: 20px 0 !important;
}

.withdrawTextInput_marginLeft20__FJS0A{
    margin: 0 0 0 20px!important;
    height: 30px!important;
}

.withdrawTextInput_marginLeft30__3ytDH{
    margin: 0 0 0 30px!important;
    height: 30px!important;
}

.withdrawItem_itemContainer__2XM0Z {
    display: flex;
    flex-direction: row;
    grid-gap: 20px;
    gap: 20px;
}

.withdrawItem_cardContent__3zTuB {
    flex-direction: column;
    align-items: center;
    color: #FFFFFF;
}

.withdrawItem_name__2_xuf {
    padding: 10px 20px;
    font-weight: 600;
    text-align: start;
    font-stretch: expanded;
    margin-bottom: 20px;
    font-size: 20px;
}

.withdrawItem_box__1XBGO {
    justify-content: space-between;
    width: 100%;
    padding: 10px 0;
    margin:0;
}

.withdrawItem_myStake__36otH {
    font-size: 12px;
    padding: 5px;
    line-height: 1.4375em;
    background: transparent;
    color: #e5e8ec;
    border-radius: 10px;
    transition: width 300ms ease;
    overflow-wrap: break-word;
}

.withdrawItem_apy__1CAlR {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    padding: 5px;
    line-height: 1.4375em;
    background: transparent;
    color: #e5e8ec;
    border-radius: 10px;
    transition: width 300ms ease;
    overflow-wrap: break-word;
}

.withdrawItem_content__Ik1oY {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
}

.withdrawItem_history___R4i- {
    font-size: 10px;
    color: #FFFFFF;
}

.withdrawItem_historyIcon__32yBs {
    fill: #E4F600 !important;
}

.withdrawItem_historyIconSm__2bii_ {
    fill: #E4F600 !important;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}


.withdrawItem_historyButton__2fcJa {
    padding: 0 !important;
}

.withdrawItem_historyContent__3oCAS {
    display: flex;
    align-items: center;
    grid-gap: 2px;
    gap: 2px;
}

@media screen and (max-width: 500px) {

    .withdrawItem_name__2_xuf {
        padding: 10px 20px;
        font-weight: 600;
        text-align: start;
        font-stretch: expanded;
        margin-bottom: 20px;
        font-size: 16px;
    }
}

.Stake_container__beeem {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0px 20px 30px 20px;
    font-size: 20px;
    color: #FFFFFF;
    font-style: normal;
    line-height: 24px;
    min-height: 74.4vh;
    margin-top: 50px;
}

.Stake_title__3Dpij {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
}

h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 49px;
    margin-bottom: 8px;
}

.Stake_toggle__2yWcY {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
}

.Stake_toggleGroup__7L4In {
    margin: 0px 0 0 20px;
    background: linear-gradient(117.9deg, rgba(255, 255, 255, 0.4) 10.75%, rgba(255, 255, 255, 0.1) 79.25%);
    box-shadow: 0px 4px 24px -1px rgba(0, 0, 0, 0.2);
    -webkit-backdrop-filter: blur(40px);
            backdrop-filter: blur(40px);
    border-radius: 20px !important;
}

.Stake_toggleGroupXs__1tkh- {
    margin: 0px 0 0 20px;
    background: linear-gradient(117.9deg, rgba(255, 255, 255, 0.4) 10.75%, rgba(255, 255, 255, 0.1) 79.25%);
    box-shadow: 0px 4px 24px -1px rgba(0, 0, 0, 0.2);
    -webkit-backdrop-filter: blur(40px);
            backdrop-filter: blur(40px);
    border-radius: 20px !important;
}

.Stake_toggleButton__1g4Da {
    font-family: 'Montserrat' !important;
    border-radius: 25px !important;
    padding: 0px 32px !important;
    font-size: 12px !important;
    text-transform: none !important;
}

.Stake_toggleButtonXs__3dUKs {
    font-family: 'Montserrat' !important;
    border-radius: 25px !important;
    padding: 0px 20px !important;
    font-size: 12px !important;
    text-transform: none !important;
}

.farmSend_send__1tXJE {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 5px;
}

.farmSend_timeLeft__2h0GK {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    width: 50px;
    height: 50px;
}

.farmSend_balance__36h72 {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    margin-left: 20px;
}

.farmSend_deposit__2-XXO {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    grid-gap: 10px;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
}

.farmSend_lp__3Wq65 {
    display: flex;
    flex-direction: row;
    grid-gap: 10px;
    gap: 10px;
    align-items: center;
}

.farmSend_marginLeft20__1ugd_{
    margin: 0 0 0 20px!important;
    height: 30px!important;
}

.farmSend_marginLeft30__ur7Sn{
    margin: 0 0 0 30px!important;
    height: 30px!important;
}

.farmTextInput_send__9XtWg {
    display: flex;
    flex-direction: column;
}

.farmTextInput_withdraw__2djvm {
    margin-top: 10px;
    padding:0;
}

.farmItem_itemContainer__3qy1a {
    display: flex;
    flex-direction: row;
    grid-gap: 20px;
    gap: 20px;
}

.farmItem_cardContent__ZEEE- {
    padding:0 30px;
    color: #FFFFFF;
}

.farmItem_name__28Ra8 {
    padding: 10px;
    font-stretch: expanded;
    font-weight: bolder;
}

.farmItem_box__1Udxr {
    justify-content: space-between;
    width: 100%;
    padding: 10px 0;
    margin:0;
}

.farmItem_contentBox__3StBU {
    align-items: center;
}

.farmItem_myStake__2dyM1 {
    font-size: 15px;
    padding: 5px;
    line-height: 1.4375em;
    background: transparent;
    color: #e5e8ec;
    border-radius: 10px;
    transition: width 300ms ease;
    overflow-wrap: break-word;
    display: flex;
}

.farmItem_apy__PgFko {
    font-size: 15px;
    padding: 5px;
    line-height: 1.4375em;
    background: transparent;
    color: #e5e8ec;
    border-radius: 10px;
    transition: width 300ms ease;
    overflow-wrap: break-word;
    display: flex;
}

.farmItem_textapy__2HJDC {
    margin-right: 90px;
}

.farmItem_content__3Uj2G {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    grid-gap: 80px;
    gap: 80px;
}

.farmItem_history__12ucz {
    font-size: 10px;
    color: #FFFFFF;
}

.farmItem_historyIcon__3JISK {
    fill: #E4F600 !important;
}

.farmItem_historyButton__1ng9s {
    padding: 0 !important;
}

.farmItem_historyContent__31HyA {
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: 2px;
    gap: 2px;
    margin-top: -30px;
}

@media screen and (max-width: 500px) {

    .farmItem_columnInfo__3tSYA {
        display: flex;
        flex-direction: row;
    }

    .farmItem_myStake__2dyM1 {
        margin: auto;
    }

}

.Farm_container__1uqjs {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: #FFFFFF;
    height: 100%;
    padding: 24px;
    grid-gap: 50px;
    gap: 50px;
}

h1 {
    margin-bottom: 8px;
}

.Farm_title__1j-l1 {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Farm_faq__2zDjh {
    padding: 45px;
}

.Farm_faqTitle__N3GnK {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 37px;
    color: #FFFFFF;
    margin-bottom: 37px;
}

.Farm_description__2Y8MV {
    max-width: 936px;
    width: 100%;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #FFFFFF;
}

a {
    text-decoration: none;
    color: #FFFFFF;
}

a:hover {
    color: #E4F600;
}

.HomeCardContent_cardContent__2lWQm {
    flex-direction: row;
    width: 95%;
    border-bottom: 1px solid #E4F600;
    padding-bottom: 15px;
    justify-content: space-between;
}

.HomeCardContent_cardAmount__vfTbi {
    color: #E4F600;
    font-size: 16px;
}

/*@media (max-width: 450px) {
    .cardContent {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        width: 200px;
        height: 80px;
    }
}*/

.Home_container__3bftD {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    margin-top: 50px;
    padding: 24px;
}

.Home_logo__2bIiM {
    display: flex;
    justify-content: center;
    padding: 30px;
}

.Home_containerCards__1JD1h {
    width: 100%;
    max-width: 1300px;
}

.Home_containerCardTextBottom__rBcMo {
    margin: 0 0 20px 0;
}

.Home_containerCardTextTop__1fzuP {
    margin: 20px 0 0 0;
}

.Home_connectContainer__39zwD {
    padding: 80px 0 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Home_marginPadding0__2XHJg {
    padding: 5px !important;
    margin: 0 !important;
}

.Home_legChar__3bjD4 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: white;
    margin: 0;
    padding: 0;
}

.Home_chartIcon__1nXAp {
    color: white !important;
    font-size: 12px !important;
    background-color: #424242 !important;
    width: 50px !important;
}

.Home_chartIconSelect__29tm8 {
    color: #171819 !important;
    font-size: 12px !important;
    background-color: #E4F600 !important;
    width: 50px !important;
}

.Nft_container__RYvYa {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    padding: 24px;
    margin-top: 50px;
}

.Nft_containerColor__29mgJ {
    color: white;
}

.Nft_selectBox__3ToQe {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-end;
    grid-gap: 20px;
    gap: 20px;
    color: #FFFFFF;
    margin-bottom: 35px;
}

.Nft_selectInput__3q-zq {
    background-color: rgb(50, 50, 50);
    width: 270px;
    font-weight: bold;
    font-size: 20px;
    margin: 10px 0;
    border-radius: 0 5px 5px 0;
    border-color: transparent;
    color: white;
}

.Nft_selectInput__3q-zq:focus {
    outline: none !important;
}

.Nft_toggle__1sRnr {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
}

.Nft_toggleGroup__nN7wO {
    margin: 0 0 0 20px;
    background: linear-gradient(117.9deg, rgba(255, 255, 255, 0.4) 10.75%, rgba(255, 255, 255, 0.1) 79.25%);
    box-shadow: 0 4px 24px -1px rgba(0, 0, 0, 0.2);
    -webkit-backdrop-filter: blur(40px);
            backdrop-filter: blur(40px);
    border-radius: 20px !important;
}

.Nft_toggleGroupXs__3Wats {
    margin: 0 0 0 20px;
    background: linear-gradient(117.9deg, rgba(255, 255, 255, 0.4) 10.75%, rgba(255, 255, 255, 0.1) 79.25%);
    box-shadow: 0 4px 24px -1px rgba(0, 0, 0, 0.2);
    -webkit-backdrop-filter: blur(40px);
            backdrop-filter: blur(40px);
    border-radius: 20px !important;
}

.Nft_toggleButton__3R0nv {
    font-family: 'Montserrat', serif !important;
    border-radius: 25px !important;
    padding: 0 32px !important;
    font-size: 12px !important;
    text-transform: none !important;
}

.Nft_toggleButtonXs__31Wc2 {
    font-family: 'Montserrat', serif !important;
    border-radius: 25px !important;
    padding: 0 20px !important;
    font-size: 12px !important;
    text-transform: none !important;
}

.Nft_linkStyle__2f67n {
    text-decoration: none;
}

.Nft_boxCard__KlzZp {
    display: flex;
    margin-top: 50px;
    grid-gap: 40px;
    gap: 40px;
}

.Nft_containerInfo__9_oqZ {
    width: 35%;
}

.Nft_tableStyle__1KhKX {
    background: rgba(23, 24, 25, 0.7);
    box-shadow: 5px 5px 50px rgba(0, 0, 0, 0.7);
    border-radius: 17px;
    padding: 15px;
}

.Nft_tableCell__3iGIU {
    font-family: 'Montserrat', serif !important;
    color: white !important;
    margin: 5px 0;
    font-size: 15px !important;
}

.Nft_tableRawBody__3ZoVn {
    border-bottom: 1px solid #E4F600 !important;
}

.Nft_loader__3VfJO {
    margin-left: 180px !important;
}

.Nft_noData__3n_5a {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 250px;
    color: #FFFFFF !important;
}

.Nft_back__2sFlW {
    margin: 0 60px;
}

.Nft_imgCard__2rg0- {
    width: 400px;
    -webkit-filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
            filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border-radius: 25px;
    margin: 0 60px;
}

.Nft_imgBox__1YVxq {
    text-align: center;
    height: 400px;
    width: 400px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.Nft_name__3O-mk {
    color: #FFFFFF;
    font-size: 36px;
}

@media screen and (max-width: 500px) {
    .Nft_back__2sFlW {
        margin: 0 30px;
    }

    .Nft_boxCard__KlzZp {
        display: block;
        margin-top: 80px;
    }

    .Nft_containerInfo__9_oqZ {
        width: 65%;
        margin: 80px auto;
    }

    .Nft_imgCard__2rg0- {
        width: 260px;
        margin: 0 30px;
    }

    .Nft_imgBox__1YVxq {
        height: 260px;
        width: 260px;
    }

    .Nft_name__3O-mk {
        color: #FFFFFF;
        font-size: 22px;
        line-height: 1.5;
    }
}

.collectionCreate_collectionContainer__1CBTz {
    color: #FFFFFF;
}

.collectionCreate_boxCard__3clI_ {
    margin-top: 25px;
    background-color: rgba(23, 24, 25, 0.7);
    box-shadow: 5px 5px 50px rgba(0, 0, 0, 0.7);
    padding: 50px 25px 25px 25px;
    border-radius: 20px;
    width: 100%;
    max-width: 900px;
    min-height: 390px;
    color: #FFFFFF;
}

.collectionCreate_label__2uShp {
    padding: 20px 0;
    font-size: 15px;
    font-stretch: expanded;
    font-weight: bolder;
}

.collectionCreate_confirmBtn__2DFv1 {
    margin: 30px 0 !important;
}

.collectionCreate_linkStyle__3nIIn {
    text-decoration: none;
}

.collectionCreate_back__3jQzI {
    display: flex;
    flex-direction: row;
    grid-gap: 30px;
    gap: 30px;
    align-items: center;
}

.collectionCreate_labelPage__287se {
    font-size: 20px;
}

@media screen and (max-width: 500px) {
    .collectionCreate_labelPage__287se {
        margin-top: 4px;
        font-size: 14px;
    }
}

#vestingTable_tableCard__T3ikF {
    width: 100% !important;
    margin: auto !important;
    box-shadow: 5px 5px 50px rgba(0, 0, 0, 0.7)!important;
    min-height: 120px;
}

#vestingTable_tableRowTitles__1wEe4 {
    height: 65px !important;
    font-family: 'Montserrat' !important;
}

#vestingTable_tableRowHead__2nyus {
    height: 60px !important;
    font-family: 'Montserrat' !important;
    border-bottom: 1px solid #E4F600!important;
    border-color: transparent!important;
}

#vestingTable_textTable__1w-SU {
    color: #e5e8ec !important;
    text-align: left !important;
    font-size: 20px !important;
    font-family: 'Montserrat' !important;
    padding: 21px 10px!important;
}

.vestingTable_css-o4b71y-MuiAccordionSummary-content__1oh6w.vestingTable_Mui-expanded__1LH9C {
    margin: 0 !important;
}

td, tr {
    border-bottom: 0 !important;
    font-family: 'Montserrat' !important;
}

.vestingTable_cards__IgcYs {
    width: 100%;
    font-family: 'Montserrat' !important;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    grid-gap: 20px;
    gap: 20px;
    padding: 16px;
}

#vestingTable_cardsOpen__3KKlc {
    width: 100%;
    display: flex;
    flex-direction: column;
    font-size: 20px;
    padding: 10px;
    line-height: 1.4375em;
    background: transparent;
    color: #e5e8ec;
    border-radius: 10px;
    transition: width 300ms ease;
    overflow-wrap: break-word;
}

.vestingTable_cardEmergency__3Gfx6 {
    display: flex;
    flex-direction: column;
}

.vestingTable_cardEmergencyComment__25Eg_ {
    font-size: 10px;
    line-height: normal;
    line-height: initial;
}

.vestingTable_cardContentUp__2j80p {
    padding: 10px !important;
}

.vestingTable_cardContentDown__3J8pr {
    display: flex;
    justify-content: space-between;
    padding: 0 20px !important;
}

.vestingTable_profit__3yq19 {
    font-size: 20px!important;
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: #E4F600;
}

.vestingTable_loading__1fbVv {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px !important;
}

.vestingTable_inputBox__1OB1p {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    grid-gap: 5px;
    gap: 5px;
}

.vestingTable_iconButtonStyleClose__FGksx {
    border-radius: 25px!important;
    border: 2px solid #E4F600!important;
    color: #E4F600!important;
    margin: 0 30px!important;
}

.vestingTable_iconButtonStyleOpen__3qFeb {
    border-radius: 25px!important;
    border: 2px solid white!important;
    color: white!important;
    margin: 0 30px!important;
}

@media screen and (max-width: 500px) {

    #vestingTable_textTable__1w-SU {
        color: #e5e8ec !important;
        text-align: left !important;
        font-size: 14px !important;
        font-family: 'Montserrat' !important;
        padding: 21px 10px!important;
    }

    #vestingTable_cardsOpen__3KKlc {
        width: 100%;
        display: flex;
        flex-direction: column;
        font-size: 14px;
        padding: 10px;
        line-height: 1.4375em;
        background: transparent;
        color: #e5e8ec;
        border-radius: 10px;
        transition: width 300ms ease;
        overflow-wrap: break-word;
    }

    .vestingTable_profit__3yq19 {
        font-size: 16px!important;
        margin-top: 10px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        color: #E4F600;
    }

}

.Vest_container__viioX {
    font-family: 'Montserrat' !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: #FFFFFF;
    font-style: normal;
    line-height: 24px;
    height: 100%;
    padding: 24px;
    margin-top: 50px;
    grid-gap: 50px;
    gap: 50px;
}

.Vest_title__3YMyc {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
}

h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 49px;
    margin-bottom: 8px;
}

.Vest_faq__1F-H3 {
    padding: 45px;
}

.Vest_faqTitle__14L79 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 37px;
    color: #FFFFFF;
    margin-bottom: 37px;
}

.Vest_description__2R5eE {
    max-width: 832px;
    width: 100%;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #FFFFFF;
}

a {
    text-decoration: none;
    color: #FFFFFF;
}

a:hover {
    color: #E4F600;
}

.loadNft_container__EIu55 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 20px;
}

.loadNft_containerColor__2SpTl {
    color: white;
    min-height: 100vh;
}

.loadNft_load__1ndM_ {
    text-decoration: none;
}

.loadNft_titleContainer__1Kdgn {
    margin: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: 30px;
    gap: 30px;
}

.loadNft_description__2nrF4 {
    width: 100% !important;
    line-height: 2.5em !important;
    padding: 2px 10px !important;
    background: rgba(66, 66, 66, 0.53) !important;
    border-radius: 8px !important;
    font-size: 13px !important;
    transition: width 300ms ease;
    color: #e5e8ec !important;
    border: transparent;
}

.loadNft_description__2nrF4:focus-visible {
    outline: none;
}

.loadNft_noData__2ELG2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 250px;
}

.loadNft_toggle__1tNMS {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
}

.loadNft_toggleGroup__2viYg {
    margin: 0 0 0 20px;
    background: linear-gradient(117.9deg, rgba(255, 255, 255, 0.4) 10.75%, rgba(255, 255, 255, 0.1) 79.25%);
    box-shadow: 0 4px 24px -1px rgba(0, 0, 0, 0.2);
    -webkit-backdrop-filter: blur(40px);
            backdrop-filter: blur(40px);
    border-radius: 20px !important;
}

.loadNft_toggleGroupXs__3kFDg {
    margin: 0 0 0 20px;
    background: linear-gradient(117.9deg, rgba(255, 255, 255, 0.4) 10.75%, rgba(255, 255, 255, 0.1) 79.25%);
    box-shadow: 0 4px 24px -1px rgba(0, 0, 0, 0.2);
    -webkit-backdrop-filter: blur(40px);
            backdrop-filter: blur(40px);
    border-radius: 20px !important;
}

.loadNft_toggleButton__2_ZuF {
    font-family: 'Montserrat', serif !important;
    border-radius: 25px !important;
    padding: 0 32px !important;
    font-size: 12px !important;
    text-transform: none !important;
}

.loadNft_toggleButtonXs__j9PLw {
    font-family: 'Montserrat', serif !important;
    border-radius: 25px !important;
    padding: 0 20px !important;
    font-size: 12px !important;
    text-transform: none !important;
}

.loadNft_linkStyle__D0tYM {
    text-decoration: none;
}

.loadNft_card__2Zbu- {
    display: flex;
    flex-direction: column;
    margin-top: 25px;
    background-color: rgba(23, 24, 25, 0.7);
    box-shadow: 5px 5px 50px rgba(0, 0, 0, 0.7);
    padding: 50px 25px 25px 25px;
    border-radius: 20px;
    width: 100%;
    max-width: 900px;
    min-height: 450px;
    color: #FFFFFF;
}

.loadNft_back__1Nxo0 {
   display: flex;
    flex-direction: row;
    grid-gap: 30px;
    gap: 30px;
    align-items: center;
}

.loadNft_boxCard__1rJ9y {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.loadNft_selectBox__2Tlw0 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    grid-gap: 20px;
    gap: 20px;
    color: #FFFFFF;
    margin-bottom: 15px;
}

.loadNft_select__kwJys {
    background-color: rgb(50, 50, 50);
    width: 250px;
    font-weight: bold;
    font-size: 16px;
    margin: 10px 0;
    border-radius: 0 5px 5px 0;
    border-color: transparent;
    color: white;
}

.loadNft_select__kwJys:focus {
    outline: none !important;
}

.loadNft_tableStyle__LYapK {
    background: rgba(23, 24, 25, 0.7);
    box-shadow: 5px 5px 50px rgba(0, 0, 0, 0.7);
    border-radius: 17px;
    padding: 15px;
}

.loadNft_tableCell__1fQHR {
    font-family: 'Montserrat', serif !important;
    color: white !important;
    margin: 5px 0;
    font-size: 15px !important;
}

.loadNft_tableRawBody__3eekZ {
    border-bottom: 1px solid #E4F600 !important;
}

.loadNft_selectInput__1U7Gb {
    background-color: rgb(50, 50, 50);
    width: 75px;
    font-weight: bold;
    font-size: 13px;
    margin: 10px 0;
    border-radius: 0 5px 5px 0;
    border-color: transparent;
    color: white;
}

.loadNft_selectInput__1U7Gb:focus {
    outline: none !important;
}

.loadNft_boxColumn__1za5H {
    margin: 0 5%;
    flex-direction: column
}

.loadNft_boxColumn1__3lDRu {
    margin: 0 5%;
    flex-direction: column
}

.loadNft_auctionInput__2w__H {
    width: 275px;
    min-width: 250px;
    line-height: 1.4375em !important;
    padding: 7px 10px !important;
    background: rgb(50, 50, 50) !important;
    font-size: 13px !important;
    color: #e5e8ec !important;
    border-radius: 5px !important;
    transition: width 300ms ease;
    border: transparent;
    margin: 10px 0;
}

.loadNft_auctionInput__2w__H:focus {
    outline: none !important;
}

.loadNft_containerButton__wa8tV {
    margin: 10px 0 0 0;
    display: flex;
    flex-direction: row;
    grid-gap: 10px;
    gap: 10px;
}

.loadNft_containerAttributes__1L7hT {
    flex-direction: row;
    display: flex;
}

.loadNft_renderContainer__2jNtr {
    width: 250px;
    min-width: 200px;
    height: 201px;
    font-size: 15px;
    border-radius: 16px;
    align-items: center;
    justify-content: center;
    display: flex;
}

.loadNft_renderIconContainer__2Y0qZ {
    padding: 0 10px;
    border-radius: 20px;
    background-color: rgba(66, 66, 66, 0.53);
    cursor: pointer;
    text-align: center;
}

.loadNft_uploadContainer__2wzSU {
    width: 250px;
    min-width: 200px;
    height: 201px;
    background-color: #424242;
    border-radius: 16px;
    align-items: center;
    justify-content: center;
    display: flex;
}

.loadNft_uploadIconContainer__13XNQ {
    width: 81px;
    height: 81px;
    border-radius: 50px;
    background: #171819;
    cursor: pointer;
    text-align: center;
}

.loadNft_upload__2XAql {
    color: #E4F600;
    font-size: 55px;
    cursor: pointer;
}

.loadNft_loader__EG9Xa {
    margin-left: 180px !important;
}

@media screen and (max-width: 1100px) {
    .loadNft_boxCard__1rJ9y {
        flex-direction: column;
        align-items: center;
    }

    .loadNft_boxColumn1__3lDRu {
        padding-top: 30px;
    }

    .loadNft_containerButton__wa8tV {
        margin: 10px 0 0 0
    }

    .loadNft_containerAttributes__1L7hT {
        flex-direction: column;
    }

}

@media screen and (max-width: 500px) {

    .loadNft_boxCard__1rJ9y {
        display: block;
        margin-top: 80px;
    }

    .loadNft_containerInfo__3lU4S {
        width: 65%;
        margin: 80px auto;
    }

    .loadNft_auctionInput__2w__H {
        width: 100%;
    }

    .loadNft_containerButton__wa8tV {
        margin: 10px 0 0 0
    }

}

.myNft_container__1B8jT {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    grid-gap: 50px;
    gap: 50px;
    font-size: 20px;
    padding: 24px;
}

.myNft_containerColor__3mvn9 {
    color: white;
    min-height: 100vh;
}

.myNft_load__3Q5Kq {
    text-decoration: none;
}

.myNft_titleContainer__3cGas {
    margin: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: 30px;
    gap: 30px;
}

.myNft_description__2xGm9 {
    width: 100% !important;
    line-height: 2.5em !important;
    padding: 2px 10px !important;
    background: rgba(66, 66, 66, 0.53) !important;
    border-radius: 8px !important;
    font-size: 13px !important;
    transition: width 300ms ease;
    color: #e5e8ec !important;
    border: transparent;
}

.myNft_description__2xGm9:focus-visible {
    outline: none;
}

.myNft_noData__2njCh {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 250px;
}

.myNft_selectBox__3wyD- {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-end;
    grid-gap: 20px;
    gap: 20px;
    color: #FFFFFF;
    margin-bottom: 35px;
}

.myNft_selectInput__N3xGD {
    background-color: rgb(50, 50, 50);
    width: 270px;
    font-weight: bold;
    font-size: 20px;
    margin: 10px 0;
    border-radius: 0 5px 5px 0;
    border-color: transparent;
    color: white;
}

.myNft_selectInput__N3xGD:focus {
    outline: none !important;
}

.myNft_toggle__3Tu6T {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
}

.myNft_toggleGroup__gaxay {
    margin: 0 0 0 20px;
    background: linear-gradient(117.9deg, rgba(255, 255, 255, 0.4) 10.75%, rgba(255, 255, 255, 0.1) 79.25%);
    box-shadow: 0 4px 24px -1px rgba(0, 0, 0, 0.2);
    -webkit-backdrop-filter: blur(40px);
            backdrop-filter: blur(40px);
    border-radius: 20px !important;
}

.myNft_toggleGroupXs__3e-ah {
    margin: 0 0 0 20px;
    background: linear-gradient(117.9deg, rgba(255, 255, 255, 0.4) 10.75%, rgba(255, 255, 255, 0.1) 79.25%);
    box-shadow: 0 4px 24px -1px rgba(0, 0, 0, 0.2);
    -webkit-backdrop-filter: blur(40px);
            backdrop-filter: blur(40px);
    border-radius: 20px !important;
}

.myNft_toggleButton__ZgPy9 {
    font-family: 'Montserrat', serif !important;
    border-radius: 25px !important;
    padding: 0 32px !important;
    font-size: 12px !important;
    text-transform: none !important;
}

.myNft_toggleButtonXs__2GRCE {
    font-family: 'Montserrat', serif !important;
    border-radius: 25px !important;
    padding: 0 20px !important;
    font-size: 12px !important;
    text-transform: none !important;
}

.myNft_linkStyle__1SfEY {
    text-decoration: none;
}

.myNft_boxCard__2Agvh {
    display: flex;
    flex-direction: row;
    margin-top: 25px;
    background-color: rgba(23, 24, 25, 0.7);
    box-shadow: 5px 5px 50px rgba(0, 0, 0, 0.7);
    padding: 50px 25px 25px 25px;
    border-radius: 20px;
    width: 100%;
    max-width: 900px;
    min-height: 450px;
    color: #FFFFFF;
}

.myNft_tableStyle__yuFtf {
    background: rgba(23, 24, 25, 0.7);
    box-shadow: 5px 5px 50px rgba(0, 0, 0, 0.7);
    border-radius: 17px;
    padding: 15px;
}

.myNft_tableCell__EPvIK {
    font-family: 'Montserrat', serif !important;
    color: white !important;
    margin: 5px 0;
    font-size: 15px !important;
}

.myNft_tableRawBody__1xKgn {
    border-bottom: 1px solid #E4F600 !important;
}


.myNft_boxColumn__38MXz {
    margin: 0 5%;
    flex-direction: column
}

.myNft_boxColumn1__2yDKN {
    margin: 0 5%;
    flex-direction: column
}

.myNft_auctionInput__OfsQi {
    width: 275px;
    min-width: 250px;
    line-height: 1.4375em !important;
    padding: 7px 10px !important;
    background: rgb(50, 50, 50) !important;
    font-size: 13px !important;
    color: #e5e8ec !important;
    border-radius: 5px !important;
    transition: width 300ms ease;
    border: transparent;
    margin: 10px 0;
}

.myNft_auctionInput__OfsQi:focus {
    outline: none !important;
}

.myNft_containerButton__3ubfr {
    margin: 10px 0 0 0
}

.myNft_containerAttributes__2nBiW {
    flex-direction: row;
    display: flex;
}

.myNft_renderContainer__1MYkK {
    width: 250px;
    min-width: 200px;
    height: 201px;
    font-size: 15px;
    border-radius: 16px;
    align-items: center;
    justify-content: center;
    display: flex;
}

.myNft_renderIconContainer__YJk3h {
    padding: 0 10px;
    border-radius: 20px;
    background-color: rgba(66, 66, 66, 0.53);
    cursor: pointer;
    text-align: center;
}

.myNft_uploadContainer__FSwLN {
    width: 250px;
    min-width: 200px;
    height: 201px;
    background-color: #424242;
    border-radius: 16px;
    align-items: center;
    justify-content: center;
    display: flex;
}

.myNft_uploadIconContainer__17tF0 {
    width: 81px;
    height: 81px;
    border-radius: 50px;
    background: #171819;
    cursor: pointer;
    text-align: center;
}

.myNft_upload__3bwVp {
    color: #E4F600;
    font-size: 55px;
    cursor: pointer;
}

.myNft_loader__3OcwS {
    margin-left: 180px !important;
}

.myNft_labelPage__1ohUW {
    font-size: 20px;
}

@media screen and (max-width: 1100px) {
    .myNft_boxCard__2Agvh {
        flex-direction: column;
        align-items: center;
    }

    .myNft_boxColumn1__2yDKN {
        padding-top: 30px;
    }

    .myNft_containerButton__3ubfr {
        margin: 10px 0 0 0
    }

    .myNft_containerAttributes__2nBiW {
        flex-direction: column;
    }

}

@media screen and (max-width: 500px) {

    .myNft_boxCard__2Agvh {
        display: block;
        margin-top: 80px;
    }

    .myNft_containerInfo__XB4G9 {
        width: 65%;
        margin: 80px auto;
    }

    .myNft_auctionInput__OfsQi {
        width: 100%;
    }

    .myNft_containerButton__3ubfr {
        margin: 10px 0 0 0
    }

    .myNft_labelPage__1ohUW {
        margin-top: 4px;
        font-size: 14px;
    }
}

.login_containerColor__12By_ {
    color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    align-content: center;
    margin-top: 100px;
}

.login_loginContainer__2TCcG {
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
}

.login_formInput__2gQIn {
    width: 275px;
    min-width:250px;
    line-height: 1.4375em !important;
    padding: 7px 10px !important;
    background: rgb(50, 50, 50) !important;
    font-size: 13px !important;
    color: #e5e8ec !important;
    border-radius: 5px !important;
    transition: width 300ms ease;
    border: transparent;
    margin: 20px 0;
}

.login_formInput__2gQIn:focus {
    outline: none !important;
}

.oldFarmSend_send__1vMde {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 5px;
}

.oldFarmSend_timeLeft__2q2xH {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    width: 50px;
    height: 50px;
}

.oldFarmSend_balance__2zb1V {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    margin-left: 20px;
}

.oldFarmSend_deposit__14zrT {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    grid-gap: 10px;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
}

.oldFarmSend_lp__3qufE {
    display: flex;
    flex-direction: row;
    grid-gap: 10px;
    gap: 10px;
    align-items: center;
}

.oldFarmSend_marginLeft20__3vwzy{
    margin: 0 0 0 20px!important;
    height: 30px!important;
}

.oldFarmSend_marginLeft30__2Teez{
    margin: 0 0 0 30px!important;
    height: 30px!important;
}

.oldFarmTextInput_send__IjDe6 {
    display: flex;
    flex-direction: column;
}

.oldFarmTextInput_withdraw__dyBKL {
    margin-top: 10px;
    padding:0;
}

.oldFarmItem_itemContainer__XTYjn {
    display: flex;
    flex-direction: row;
    grid-gap: 20px;
    gap: 20px;
}

.oldFarmItem_cardContent__2D52Z {
    padding:0 30px;
    color: #FFFFFF;
}

.oldFarmItem_name__2LCtd {
    padding: 10px;
    font-stretch: expanded;
    font-weight: bolder;
}

.oldFarmItem_box__1v66G {
    justify-content: space-between;
    width: 100%;
    padding: 10px 0;
    margin:0;
}

.oldFarmItem_contentBox__1P73j {
    align-items: center;
}

.oldFarmItem_myStake__1qJru {
    font-size: 15px;
    padding: 5px;
    line-height: 1.4375em;
    background: transparent;
    color: #e5e8ec;
    border-radius: 10px;
    transition: width 300ms ease;
    overflow-wrap: break-word;
    display: flex;
}

.oldFarmItem_apy__3CRbJ {
    font-size: 15px;
    padding: 5px;
    line-height: 1.4375em;
    background: transparent;
    color: #e5e8ec;
    border-radius: 10px;
    transition: width 300ms ease;
    overflow-wrap: break-word;
    display: flex;
}

.oldFarmItem_textapy__3Optw {
    margin-right: 90px;
}

.oldFarmItem_content__3J9m6 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    grid-gap: 80px;
    gap: 80px;
}

.oldFarmItem_history__gAWGr {
    font-size: 10px;
    color: #FFFFFF;
}

.oldFarmItem_historyIcon__1pKa4 {
    fill: #E4F600 !important;
}

.oldFarmItem_historyButton__1YNPB {
    padding: 0 !important;
}

.oldFarmItem_historyContent__6paaU {
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: 2px;
    gap: 2px;
    margin-top: -30px;
}

@media screen and (max-width: 500px) {

    .oldFarmItem_columnInfo__mqs8i {
        display: flex;
        flex-direction: row;
    }

    .oldFarmItem_myStake__1qJru {
        margin: auto;
    }

}

.OldFarm_container__3KsIj {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: #FFFFFF;
    height: 100%;
    padding: 24px;
    grid-gap: 50px;
    gap: 50px;
}

h1 {
    margin-bottom: 8px;
}

.OldFarm_title__JSljQ {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.OldFarm_faq__1dkgP {
    padding: 45px;
}

.OldFarm_faqTitle__Aar7O {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 37px;
    color: #FFFFFF;
    margin-bottom: 37px;
}

.OldFarm_description__sornR {
    max-width: 936px;
    width: 100%;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #FFFFFF;
}

a {
    text-decoration: none;
    color: #FFFFFF;
}

a:hover {
    color: #E4F600;
}

body {
    background-color: #171819;
    font-family: 'Montserrat' !important;
    margin: 0;
    min-height: 100% !important;
}

.css-ueukts-MuiButtonBase-root-MuiToggleButton-root.Mui-selected {
    color: #171819;
    background-color: #E4F600 !important;
}

.css-1ex1afd-MuiTableCell-root, .css-1ygcj2i-MuiTableCell-root {
    padding: 15px 10px !important;
}

.MuiPaper-root {
    color: #fff !important; 
    background-color: #171819 !important;
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
    font-family: 'Montserrat' !important;
}


.css-10hburv-MuiTypography-root {
    font-family: 'Montserrat' !important;
}

.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked {
    color: #E4F600!important;
}

