.bar {
    background-color: #171819 !important;
    font-family: 'Montserrat' !important;
    min-height: 90px;
    padding-right: 0!important;
}

.routeBtn {
    font-family: 'Montserrat' !important;
    text-transform: none !important;
    margin-left: 20px !important;
    color: #FFFFFF !important;
}

.balance {
    display: flex;
    align-items: center;
    font-family: 'Montserrat' !important;
    font-style: normal;
    font-weight: 600;
    font-size: 15px !important;
    line-height: 18px;
    flex-wrap: wrap;
    color: #FFFFFF;
}

.trendIcon {
    fill: #E4F600 !important;
    width: 10px!important;
}

.accountText {
    max-width: 150px;
    text-overflow: ellipsis;
    font-size: 10px;
    margin:0;
    padding:5px!important;
    overflow: hidden;
    white-space: nowrap;
}

.marginPadding0{
    padding:5px!important;
    margin:0!important;
}

.selected {
    font-family: 'Montserrat' !important;
    margin: 20px 0 !important;
    background: linear-gradient(270deg, #E4F600 5.48%, rgba(173, 235, 48, 0.5) 88.01%) !important;
    border-radius: 0 0 0 20px !important;
    color: white !important;
}

.selectedMenu {
    font-family: 'Montserrat' !important;
    background: linear-gradient(270deg, #E4F600 5.48%, rgba(173, 235, 48, 0.5) 88.01%) !important;
    border-radius: 0 0 0 20px !important;
    color: white !important;
}

.txt {
    font-family: 'Montserrat' !important;
}
