.itemContainer {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.cardContent {
    flex-direction: column;
    align-items: center;
    color: #FFFFFF;
}

.name {
    padding: 10px 20px;
    font-weight: 600;
    text-align: start;
    font-stretch: expanded;
    margin-bottom: 20px;
    font-size: 20px;
}

.box {
    justify-content: space-between;
    width: 100%;
    padding: 10px 0;
    margin:0;
}

.myStake {
    font-size: 12px;
    padding: 5px;
    line-height: 1.4375em;
    background: transparent;
    color: #e5e8ec;
    border-radius: 10px;
    transition: width 300ms ease;
    overflow-wrap: break-word;
}

.apy {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    padding: 5px;
    line-height: 1.4375em;
    background: transparent;
    color: #e5e8ec;
    border-radius: 10px;
    transition: width 300ms ease;
    overflow-wrap: break-word;
}

.content {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

.history {
    font-size: 10px;
    color: #FFFFFF;
}

.historyIcon {
    fill: #E4F600 !important;
}

.historyIconSm {
    fill: #E4F600 !important;
    transform: rotate(90deg);
}


.historyButton {
    padding: 0 !important;
}

.historyContent {
    display: flex;
    align-items: center;
    gap: 2px;
}

@media screen and (max-width: 500px) {

    .name {
        padding: 10px 20px;
        font-weight: 600;
        text-align: start;
        font-stretch: expanded;
        margin-bottom: 20px;
        font-size: 16px;
    }
}
