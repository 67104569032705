.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    padding: 24px;
    margin-top: 50px;
}

.containerColor {
    color: white;
}

.selectBox {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-end;
    gap: 20px;
    color: #FFFFFF;
    margin-bottom: 35px;
}

.selectInput {
    background-color: rgb(50, 50, 50);
    width: 270px;
    font-weight: bold;
    font-size: 20px;
    margin: 10px 0;
    border-radius: 0 5px 5px 0;
    border-color: transparent;
    color: white;
}

.selectInput:focus {
    outline: none !important;
}

.toggle {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
}

.toggleGroup {
    margin: 0 0 0 20px;
    background: linear-gradient(117.9deg, rgba(255, 255, 255, 0.4) 10.75%, rgba(255, 255, 255, 0.1) 79.25%);
    box-shadow: 0 4px 24px -1px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(40px);
    border-radius: 20px !important;
}

.toggleGroupXs {
    margin: 0 0 0 20px;
    background: linear-gradient(117.9deg, rgba(255, 255, 255, 0.4) 10.75%, rgba(255, 255, 255, 0.1) 79.25%);
    box-shadow: 0 4px 24px -1px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(40px);
    border-radius: 20px !important;
}

.toggleButton {
    font-family: 'Montserrat', serif !important;
    border-radius: 25px !important;
    padding: 0 32px !important;
    font-size: 12px !important;
    text-transform: none !important;
}

.toggleButtonXs {
    font-family: 'Montserrat', serif !important;
    border-radius: 25px !important;
    padding: 0 20px !important;
    font-size: 12px !important;
    text-transform: none !important;
}

.linkStyle {
    text-decoration: none;
}

.boxCard {
    display: flex;
    margin-top: 50px;
    gap: 40px;
}

.containerInfo {
    width: 35%;
}

.tableStyle {
    background: rgba(23, 24, 25, 0.7);
    box-shadow: 5px 5px 50px rgba(0, 0, 0, 0.7);
    border-radius: 17px;
    padding: 15px;
}

.tableCell {
    font-family: 'Montserrat', serif !important;
    color: white !important;
    margin: 5px 0;
    font-size: 15px !important;
}

.tableRawBody {
    border-bottom: 1px solid #E4F600 !important;
}

.loader {
    margin-left: 180px !important;
}

.noData {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 250px;
    color: #FFFFFF !important;
}

.back {
    margin: 0 60px;
}

.imgCard {
    width: 400px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border-radius: 25px;
    margin: 0 60px;
}

.imgBox {
    text-align: center;
    height: 400px;
    width: 400px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.name {
    color: #FFFFFF;
    font-size: 36px;
}

@media screen and (max-width: 500px) {
    .back {
        margin: 0 30px;
    }

    .boxCard {
        display: block;
        margin-top: 80px;
    }

    .containerInfo {
        width: 65%;
        margin: 80px auto;
    }

    .imgCard {
        width: 260px;
        margin: 0 30px;
    }

    .imgBox {
        height: 260px;
        width: 260px;
    }

    .name {
        color: #FFFFFF;
        font-size: 22px;
        line-height: 1.5;
    }
}
