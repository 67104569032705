.values {
    padding: 20px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #e5e8ec;
  }

.header {
    background: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.logo {
    width: 100px;
    padding: 5px;
}

.title {
    font-size: 1.5em;
}

td {
    color: #e5e8ec !important;
}