.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: #FFFFFF;
    height: 100%;
    padding: 24px;
    gap: 50px;
}

h1 {
    margin-bottom: 8px;
}

.title {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.faq {
    padding: 45px;
}

.faqTitle {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 37px;
    color: #FFFFFF;
    margin-bottom: 37px;
}

.description {
    max-width: 936px;
    width: 100%;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #FFFFFF;
}

a {
    text-decoration: none;
    color: #FFFFFF;
}

a:hover {
    color: #E4F600;
}
