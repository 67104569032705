.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 20px;
}

.containerColor {
    color: white;
    min-height: 100vh;
}

.load {
    text-decoration: none;
}

.titleContainer {
    margin: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 30px;
}

.description {
    width: 100% !important;
    line-height: 2.5em !important;
    padding: 2px 10px !important;
    background: rgba(66, 66, 66, 0.53) !important;
    border-radius: 8px !important;
    font-size: 13px !important;
    transition: width 300ms ease;
    color: #e5e8ec !important;
    border: transparent;
}

.description:focus-visible {
    outline: none;
}

.noData {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 250px;
}

.toggle {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
}

.toggleGroup {
    margin: 0 0 0 20px;
    background: linear-gradient(117.9deg, rgba(255, 255, 255, 0.4) 10.75%, rgba(255, 255, 255, 0.1) 79.25%);
    box-shadow: 0 4px 24px -1px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(40px);
    border-radius: 20px !important;
}

.toggleGroupXs {
    margin: 0 0 0 20px;
    background: linear-gradient(117.9deg, rgba(255, 255, 255, 0.4) 10.75%, rgba(255, 255, 255, 0.1) 79.25%);
    box-shadow: 0 4px 24px -1px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(40px);
    border-radius: 20px !important;
}

.toggleButton {
    font-family: 'Montserrat', serif !important;
    border-radius: 25px !important;
    padding: 0 32px !important;
    font-size: 12px !important;
    text-transform: none !important;
}

.toggleButtonXs {
    font-family: 'Montserrat', serif !important;
    border-radius: 25px !important;
    padding: 0 20px !important;
    font-size: 12px !important;
    text-transform: none !important;
}

.linkStyle {
    text-decoration: none;
}

.card {
    display: flex;
    flex-direction: column;
    margin-top: 25px;
    background-color: rgba(23, 24, 25, 0.7);
    box-shadow: 5px 5px 50px rgba(0, 0, 0, 0.7);
    padding: 50px 25px 25px 25px;
    border-radius: 20px;
    width: 100%;
    max-width: 900px;
    min-height: 450px;
    color: #FFFFFF;
}

.back {
   display: flex;
    flex-direction: row;
    gap: 30px;
    align-items: center;
}

.boxCard {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.selectBox {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 20px;
    color: #FFFFFF;
    margin-bottom: 15px;
}

.select {
    background-color: rgb(50, 50, 50);
    width: 250px;
    font-weight: bold;
    font-size: 16px;
    margin: 10px 0;
    border-radius: 0 5px 5px 0;
    border-color: transparent;
    color: white;
}

.select:focus {
    outline: none !important;
}

.tableStyle {
    background: rgba(23, 24, 25, 0.7);
    box-shadow: 5px 5px 50px rgba(0, 0, 0, 0.7);
    border-radius: 17px;
    padding: 15px;
}

.tableCell {
    font-family: 'Montserrat', serif !important;
    color: white !important;
    margin: 5px 0;
    font-size: 15px !important;
}

.tableRawBody {
    border-bottom: 1px solid #E4F600 !important;
}

.selectInput {
    background-color: rgb(50, 50, 50);
    width: 75px;
    font-weight: bold;
    font-size: 13px;
    margin: 10px 0;
    border-radius: 0 5px 5px 0;
    border-color: transparent;
    color: white;
}

.selectInput:focus {
    outline: none !important;
}

.boxColumn {
    margin: 0 5%;
    flex-direction: column
}

.boxColumn1 {
    margin: 0 5%;
    flex-direction: column
}

.auctionInput {
    width: 275px;
    min-width: 250px;
    line-height: 1.4375em !important;
    padding: 7px 10px !important;
    background: rgb(50, 50, 50) !important;
    font-size: 13px !important;
    color: #e5e8ec !important;
    border-radius: 5px !important;
    transition: width 300ms ease;
    border: transparent;
    margin: 10px 0;
}

.auctionInput:focus {
    outline: none !important;
}

.containerButton {
    margin: 10px 0 0 0;
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.containerAttributes {
    flex-direction: row;
    display: flex;
}

.renderContainer {
    width: 250px;
    min-width: 200px;
    height: 201px;
    font-size: 15px;
    border-radius: 16px;
    align-items: center;
    justify-content: center;
    display: flex;
}

.renderIconContainer {
    padding: 0 10px;
    border-radius: 20px;
    background-color: rgba(66, 66, 66, 0.53);
    cursor: pointer;
    text-align: center;
}

.uploadContainer {
    width: 250px;
    min-width: 200px;
    height: 201px;
    background-color: #424242;
    border-radius: 16px;
    align-items: center;
    justify-content: center;
    display: flex;
}

.uploadIconContainer {
    width: 81px;
    height: 81px;
    border-radius: 50px;
    background: #171819;
    cursor: pointer;
    text-align: center;
}

.upload {
    color: #E4F600;
    font-size: 55px;
    cursor: pointer;
}

.loader {
    margin-left: 180px !important;
}

@media screen and (max-width: 1100px) {
    .boxCard {
        flex-direction: column;
        align-items: center;
    }

    .boxColumn1 {
        padding-top: 30px;
    }

    .containerButton {
        margin: 10px 0 0 0
    }

    .containerAttributes {
        flex-direction: column;
    }

}

@media screen and (max-width: 500px) {

    .boxCard {
        display: block;
        margin-top: 80px;
    }

    .containerInfo {
        width: 65%;
        margin: 80px auto;
    }

    .auctionInput {
        width: 100%;
    }

    .containerButton {
        margin: 10px 0 0 0
    }

}
