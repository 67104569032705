#tableCard {
    width: 100% !important;
    margin: auto !important;
    box-shadow: 5px 5px 50px rgba(0, 0, 0, 0.7)!important;
    min-height: 120px;
}

#tableRowTitles {
    height: 65px !important;
    font-family: 'Montserrat' !important;
}

#tableRowHead {
    height: 60px !important;
    font-family: 'Montserrat' !important;
    border-bottom: 1px solid #E4F600!important;
    border-color: transparent!important;
}

#textTable {
    color: #e5e8ec !important;
    text-align: left !important;
    font-size: 20px !important;
    font-family: 'Montserrat' !important;
    padding: 21px 10px!important;
}

.css-o4b71y-MuiAccordionSummary-content.Mui-expanded {
    margin: 0 !important;
}

td, tr {
    border-bottom: 0 !important;
    font-family: 'Montserrat' !important;
}

.cards {
    width: 100%;
    font-family: 'Montserrat' !important;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    padding: 16px;
}

#cardsOpen {
    width: 100%;
    display: flex;
    flex-direction: column;
    font-size: 20px;
    padding: 10px;
    line-height: 1.4375em;
    background: transparent;
    color: #e5e8ec;
    border-radius: 10px;
    transition: width 300ms ease;
    overflow-wrap: break-word;
}

.cardEmergency {
    display: flex;
    flex-direction: column;
}

.cardEmergencyComment {
    font-size: 10px;
    line-height: initial;
}

.cardContentUp {
    padding: 10px !important;
}

.cardContentDown {
    display: flex;
    justify-content: space-between;
    padding: 0 20px !important;
}

.profit {
    font-size: 20px!important;
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: #E4F600;
}

.loading {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px !important;
}

.inputBox {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.iconButtonStyleClose {
    border-radius: 25px!important;
    border: 2px solid #E4F600!important;
    color: #E4F600!important;
    margin: 0 30px!important;
}

.iconButtonStyleOpen {
    border-radius: 25px!important;
    border: 2px solid white!important;
    color: white!important;
    margin: 0 30px!important;
}

@media screen and (max-width: 500px) {

    #textTable {
        color: #e5e8ec !important;
        text-align: left !important;
        font-size: 14px !important;
        font-family: 'Montserrat' !important;
        padding: 21px 10px!important;
    }

    #cardsOpen {
        width: 100%;
        display: flex;
        flex-direction: column;
        font-size: 14px;
        padding: 10px;
        line-height: 1.4375em;
        background: transparent;
        color: #e5e8ec;
        border-radius: 10px;
        transition: width 300ms ease;
        overflow-wrap: break-word;
    }

    .profit {
        font-size: 16px!important;
        margin-top: 10px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        color: #E4F600;
    }

}
