.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    margin-top: 50px;
    padding: 24px;
}

.logo {
    display: flex;
    justify-content: center;
    padding: 30px;
}

.containerCards {
    width: 100%;
    max-width: 1300px;
}

.containerCardTextBottom {
    margin: 0 0 20px 0;
}

.containerCardTextTop {
    margin: 20px 0 0 0;
}

.connectContainer {
    padding: 80px 0 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.marginPadding0 {
    padding: 5px !important;
    margin: 0 !important;
}

.legChar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: white;
    margin: 0;
    padding: 0;
}

.chartIcon {
    color: white !important;
    font-size: 12px !important;
    background-color: #424242 !important;
    width: 50px !important;
}

.chartIconSelect {
    color: #171819 !important;
    font-size: 12px !important;
    background-color: #E4F600 !important;
    width: 50px !important;
}
