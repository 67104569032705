.collectionContainer {
    color: #FFFFFF;
}

.boxCard {
    margin-top: 25px;
    background-color: rgba(23, 24, 25, 0.7);
    box-shadow: 5px 5px 50px rgba(0, 0, 0, 0.7);
    padding: 50px 25px 25px 25px;
    border-radius: 20px;
    width: 100%;
    max-width: 900px;
    min-height: 390px;
    color: #FFFFFF;
}

.label {
    padding: 20px 0;
    font-size: 15px;
    font-stretch: expanded;
    font-weight: bolder;
}

.confirmBtn {
    margin: 30px 0 !important;
}

.linkStyle {
    text-decoration: none;
}

.back {
    display: flex;
    flex-direction: row;
    gap: 30px;
    align-items: center;
}

.labelPage {
    font-size: 20px;
}

@media screen and (max-width: 500px) {
    .labelPage {
        margin-top: 4px;
        font-size: 14px;
    }
}
