.send {
    padding: 0 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 5px;
}

.timeLeft {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    width: 100px;
}

.disabledBtn {
    background-color: grey !important;

}

.enableBtn {
    background: linear-gradient(270deg, rgba(173, 235, 48, 0.5) 5.48%,#E4F600  88.01%) !important;
    color: white!important;
    padding: 20px 0 !important;
}

.marginLeft20{
    margin: 0 0 0 20px!important;
    height: 30px!important;
}

.marginLeft30{
    margin: 0 0 0 30px!important;
    height: 30px!important;
}
